import React from 'react'
import './navbar.css'
import Logost from '../../img/[removal.ai]_tmp-642d1fd745f9b.png'
function Navbar() {
    return (
        <div className='Navbar'>
            <nav>
                <div className="logopage">
                    <img src={Logost} alt="" />
                    <h1>Richard</h1>
                </div>

                <ul>
                    <li>Home </li>
                    <li>About</li>
                    <li>Resume</li>
                    <li>Hacking</li>
                    <li>Certifications</li>
                    <li>Path</li>
                </ul>
            </nav>
            <div className="profil">

            </div>
        </div >
    )
}

export default Navbar