import React from 'react'
import './footer.css'
import { FaLinkedinIn } from 'react-icons/fa';
import { MdOutlineMarkEmailUnread } from 'react-icons/md';
import { FaTwitter } from 'react-icons/fa'

function Footer() {
  return (
    <div className='Footer'>
      <div className="education">
        <h1>Education</h1>
        <div className="ew">

          <p>2019</p>
          <h2>Enter Your Degree</h2>
          <h3>High School Diploma</h3>
        </div>
        <div className="ew">
          <p>2022</p>
          <h2>SEJONG UNIVERSITY</h2>
          <h3>Having a career now in</h3>
        </div>
      </div>
      <div className="expertise">
        <h1>Expertise</h1>
        <p>Linux Fundamentals</p>
        <p>Networking</p>
        <p>Hacking WordPress</p>
        <p>Metasploit</p>
        <p>nformation Security</p>
        <p>nformation Security</p>
      </div>
      <div className="language">
        <h1>Language</h1>
        <p>Englis</p>
        <p>Korean</p>
        <p>Russian</p>
        <p>Turkish</p>
      </div>

      <div className="contact">
        <a href="https://www.linkedin.com/in/oybek-fattakhov-559333261"> <FaLinkedinIn className='link' color='#21ef00' size={'1.5vw'} /> </a>
        <a href="oybekfattakhov07@gmail.com"> <MdOutlineMarkEmailUnread className='email' color='#21ef00' size={'1.5vw'} /> </a>
        <a href="https://twitter.com/Uncanny_21?t=AlA_iqurdE-3vIoqyX1erA&s=35"> <FaTwitter className='twiter' color='#21ef00' size={'1.5vw'} /> </a>
      </div>
    </div>
  )
}

export default Footer